.App {
  text-align: center;
}

.leftUpper {
  position: absolute;
  top: 36vh;
  left: 32vw;
  z-index: 99;
  font-size: 30px;
  font-weight: bold;
}

.leftLower {
  position: absolute;
  top: 60vh;
  left: 32vw;
  z-index: 99;
  font-size: 30px;
  font-weight: bold;
}

.rightUpper {
  position: absolute;
  top: 60vh;
  right: 32vw;
  z-index: 99;
  font-size: 30px;
  font-weight: bold;
}

.rightLower {
  position: absolute;
  top: 36vh;
  right: 32vw;
  z-index: 99;
  font-size: 30px;
  font-weight: bold;
}

.greyBG {
  border-radius: 50px;
  padding: 50px;
  background-color: #f3f3f3;
}

.chartBG {
  height: 617px;
  width: 617px;
  background-size: cover;
  background-image: url("./ChartUpdated.png");
  background-color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.boventitel {
  font-size: 70px
}

.ondertitel {
  font-size: 40px;
  font-weight: 500!important;
}

.hero {
  background-color: #282c34;
  background-image: url("./Hero.jpg");
  background-size: cover;
  height: 50VH;
}
.introduction {
  height: 100px;
  background-color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
}

body {
  font-family: ROsanswebtextregular, sans-serif !important;
  color: #333;
  font-size: 1rem;
  line-height: 1.5;
}

h1 {
  margin-top: 0.6rem!important;
  margin-bottom: 0rem;
  font-size: 2rem!important;
  line-height: 1;
}

h2 {
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-size: 3rem;
  line-height: 1.2;
  font-weight: 700;
}

h3 {
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 700;
}

h4 {
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-size: 1.25rem;
  line-height: 1.4;
  font-weight: 700;
}

h5 {
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: 700;
}

h6 {
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-size: 0.75rem;
  line-height: 1.5;
  font-weight: 700;
}

p {
  margin-bottom: 0rem;
}

ul {
  margin-top: 0rem;
  margin-bottom: 0rem;
  padding-left: 1.25rem;
}

ol {
  margin-top: 0rem;
  margin-bottom: 0rem;
  padding-left: 1.25rem;
}

li {
  margin-bottom: 0.25rem;
}

img {
  display: inline-block;
  max-width: 100%;
}

label {
  margin-bottom: 0.25rem;
  font-weight: 500;
}

strong {
  font-family: ROsanswebtextbold, sans-serif;
  font-weight: 700;
}

blockquote {
  margin-bottom: 0rem;
  padding: 0.75rem 1.25rem;
  border-left: 0.25rem solid #e2e2e2;
  font-size: 1.25rem;
  line-height: 1.5;
}

figure {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

figcaption {
  margin-top: 0.25rem;
  text-align: center;
}

.form-message-success {
  padding: 1.25rem;
}

.z-2 {
  position: relative;
  z-index: 2;
}

.text-color-black {
  color: #000;
}

.fs-styleguide_row {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
  justify-content: start;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.fs-styleguide_row.is-for-social-icons {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
}

.text-style-strikethrough {
  text-decoration: line-through;
}

.fs-styleguide_3-col {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.text-weight-semibold {
  font-weight: 600;
}

.text-weight-light {
  font-weight: 300;
}

.max-width-full {
  width: 100%;
  max-width: none;
}

.layer {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.show {
  display: block;
}

.fs-styleguide_color-sample {
  margin-top: 0.5rem;
  padding: 5rem;
  background-color: #f3f3f3;
}

.overflow-hidden {
  overflow: hidden;
}

.global-styles {
  position: fixed;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  display: block;
}

.icon-1x1-small {
  width: 2rem;
  height: 2rem;
}

.button {
  padding: 0.75rem 1.25rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #386eec;
  color: #fff;
  font-weight: 600;
  text-align: center;
}

.heading-medium {
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 700;
}

.heading-large {
  font-size: 3rem;
  line-height: 1.2;
  font-weight: 700;
}

.text-weight-bold {
  font-weight: 700;
}

.text-size-large {
  font-size: 1.5rem;
}

.icon-medium {
  height: 3rem;
}

.text-size-regular {
  font-size: 1rem;
}

.text-weight-normal {
  font-weight: 400;
}

.fs-styleguide_section {
  display: -ms-grid;
  display: grid;
  padding-top: 2rem;
  padding-bottom: 2rem;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 4rem;
  grid-row-gap: 2.5rem;
  -ms-grid-columns: 0.5fr 1fr;
  grid-template-columns: 0.5fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.text-align-center {
  text-align: center;
}

.z-1 {
  position: relative;
  z-index: 1;
}

.icon-small {
  height: 2rem;
}

.show-mobile-portrait {
  display: none;
}

.text-style-italic {
  font-style: italic;
}

.background-color-grey {
  background-color: rgba(168, 193, 216, 0.2);
}

.hide {
  display: none;
}

.fs-styleguide_section-type {
  padding: 1.25rem;
  background-color: #000;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.text-size-medium {
  max-width: 529px;
  font-size: 1.25rem;
}

.text-align-left {
  text-align: left;
}

.text-weight-xbold {
  font-weight: 800;
}

.text-style-link {
  text-decoration: underline;
}

.form-wrapper {
  margin-bottom: 0rem;
}

.text-style-muted {
  opacity: 0.6;
}

.text-size-small {
  margin-bottom: 1rem;
  font-size: 0.875rem;
}

.text-rich-text h1 {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.text-rich-text h2 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.text-rich-text h3 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.text-rich-text h4 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.text-rich-text h5 {
  margin-top: 1.25rem;
  margin-bottom: 0.75rem;
}

.text-rich-text h6 {
  margin-top: 1.25rem;
  margin-bottom: 0.5rem;
}

.text-rich-text p {
  margin-bottom: 0.75rem;
}

.text-rich-text blockquote {
  margin-bottom: 0.75rem;
}

.text-rich-text ul {
  margin-bottom: 0.75rem;
}

.max-width-xlarge {
  width: 100%;
  max-width: 64rem;
}

.icon-large {
  height: 4rem;
}

.max-width-medium {
  width: 100%;
  max-width: 32rem;
}

.form-radio {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.5rem;
  padding-left: 0rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading-xlarge {
  font-size: 4rem;
  line-height: 1.1;
  font-weight: 700;
}

.max-width-xsmall {
  width: 100%;
  max-width: 16rem;
}

.form-input {
  min-height: 3rem;
  margin-bottom: 0.75rem;
  padding: 0.5rem 1rem;
  background-color: transparent;
}

.form-input.is-text-area {
  min-height: 8rem;
  padding-top: 0.75rem;
}

.max-width-xxsmall {
  width: 100%;
  max-width: 12rem;
}

.text-size-tiny {
  font-size: 0.75rem;
}

.icon-1x1-medium {
  width: 3rem;
  height: 3rem;
}

.form-checkbox-icon {
  width: 0.875rem;
  height: 0.875rem;
  margin: 0rem 0.5rem 0rem 0rem;
  border-radius: 0.125rem;
}

.form-checkbox-icon.w--redirected-checked {
  width: 0.875rem;
  height: 0.875rem;
  margin: 0rem 0.5rem 0rem 0rem;
  border-radius: 0.125rem;
  background-size: 90%;
}

.form-checkbox-icon.w--redirected-focus {
  width: 0.875rem;
  height: 0.875rem;
  margin: 0rem 0.5rem 0rem 0rem;
  border-radius: 0.125rem;
  box-shadow: 0 0 0.25rem 0rem #3898ec;
}

.container-large {
  width: 100%;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
}

.max-width-xxlarge {
  width: 100%;
  max-width: 80rem;
}

.background-color-black {
  background-color: #000;
  color: #f3f3f3;
}

.text-style-quote {
  margin-bottom: 0rem;
  padding: 0.75rem 1.25rem;
  border-left: 0.25rem solid #e2e2e2;
  font-size: 1.25rem;
  line-height: 1.5;
}

.fs-styleguide_classes {
  display: none;
}

.align-center {
  margin-right: auto;
  margin-left: auto;
}

.fs-styleguide_1-col {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.fs-styleguide_1-col.gap-medium {
  grid-row-gap: 2.5rem;
}

.spacing-clean {
  margin: 0rem;
  padding: 0rem;
}

.page-padding {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.fs-styleguide_2-col {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
  justify-content: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.text-align-right {
  text-align: right;
}

.show-tablet {
  display: none;
}

.max-width-large {
  width: 100%;
  max-width: 48rem;
}

.text-color-grey {
  color: grey;
}

.form-radio-icon {
  width: 0.875rem;
  height: 0.875rem;
  margin-top: 0rem;
  margin-right: 0.5rem;
  margin-left: 0rem;
}

.form-radio-icon.w--redirected-checked {
  width: 0.875rem;
  height: 0.875rem;
  border-width: 0.25rem;
}

.form-radio-icon.w--redirected-focus {
  width: 0.875rem;
  height: 0.875rem;
  box-shadow: 0 0 0.25rem 0rem #3898ec;
}

.show-mobile-landscape {
  display: none;
}

.background-color-white {
  background-color: #fff;
}

.max-width-small {
  width: 100%;
  max-width: 20rem;
}

.icon-1x1-large {
  width: 4rem;
  height: 4rem;
}

.form-checkbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.5rem;
  padding-left: 0rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-message-error {
  margin-top: 0.75rem;
  padding: 0.75rem;
}

.utility_component {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility_form-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 20rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility_form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.utility_image {
  margin-right: auto;
  margin-bottom: 0.5rem;
  margin-left: auto;
}

.container-medium {
  width: 100%;
  max-width: 64rem;
  margin-right: auto;
  margin-left: auto;
}

.container-small {
  width: 100%;
  max-width: 48rem;
  margin-right: auto;
  margin-left: auto;
}

.button-secondary {
  padding: 0.75rem 1.25rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0.125rem solid #386eec;
  background-color: transparent;
  color: #386eec;
  font-weight: 600;
  text-align: center;
}

.button-text {
  padding: 0.75rem 1.25rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  color: #386eec;
  font-weight: 600;
  text-align: center;
}

.margin-tiny {
  margin: 0.125rem;
}

.margin-xxsmall {
  margin: 0.25rem;
}

.margin-xsmall {
  margin: 0.5rem;
}

.margin-small {
  margin: 1rem;
}

.margin-medium {
  margin: 2rem;
}

.margin-large {
  margin: 3rem;
}

.margin-xlarge {
  margin: 4rem;
}

.margin-xxlarge {
  margin: 5rem;
}

.margin-huge {
  margin: 6rem;
}

.margin-xhuge {
  margin: 8rem;
}

.margin-xxhuge {
  margin: 12rem;
}

.margin-0 {
  margin: 0rem;
}

.padding-0 {
  padding: 0rem;
}

.padding-tiny {
  padding: 0.125rem;
}

.padding-xxsmall {
  padding: 0.25rem;
}

.padding-xsmall {
  padding: 0.5rem;
}

.padding-small {
  padding: 1rem;
}

.padding-medium {
  padding: 2rem;
}

.padding-large {
  padding: 3rem;
}

.padding-xlarge {
  padding: 4rem;
}

.padding-xxlarge {
  padding: 5rem;
}

.padding-huge {
  padding: 6rem;
}

.padding-xhuge {
  padding: 8rem;
}

.padding-xxhuge {
  padding: 12rem;
}

.text-style-allcaps {
  text-transform: uppercase;
}

.margin-top {
  margin-right: 0rem;
  margin-bottom: 0rem;
  margin-left: 0rem;
}

.margin-bottom {
  margin-top: 0rem;
  margin-right: 0rem;
  margin-left: 0rem;
}

.margin-left {
  margin-top: 0rem;
  margin-right: 0rem;
  margin-bottom: 0rem;
}

.margin-right {
  margin-top: 0rem;
  margin-bottom: 0rem;
  margin-left: 0rem;
}

.margin-vertical {
  margin-right: 0rem;
  margin-left: 0rem;
}

.margin-horizontal {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.padding-top {
  padding-right: 0rem;
  padding-bottom: 0rem;
  padding-left: 0rem;
}

.padding-bottom {
  padding-top: 0rem;
  padding-right: 0rem;
  padding-left: 0rem;
}

.padding-left {
  padding-top: 0rem;
  padding-right: 0rem;
  padding-bottom: 0rem;
}

.padding-right {
  padding-top: 0rem;
  padding-bottom: 0rem;
  padding-left: 0rem;
}

.padding-vertical {
  padding-right: 0rem;
  padding-left: 0rem;
}

.padding-horizontal {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-auto {
  overflow: auto;
}

.fs-styleguide_background-spacer {
  height: 5rem;
}

.text-style-nowrap {
  white-space: nowrap;
}

.heading-small {
  font-size: 1.25rem;
  line-height: 1.4;
  font-weight: 700;
}

.heading-xsmall {
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: 700;
}

.text-weight-medium {
  font-weight: 500;
}

.fs-styleguide_version-details {
  z-index: 5;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.fs-styleguide_page-header {
  margin-bottom: 2rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
  border-bottom: 2px solid #e6e6e6;
}

.fs-styleguide_message-outer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  padding: 0.75rem;
  color: #fff;
}

.fs-styleguide_message-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  border-radius: 1.5rem;
  background-color: #1a1a1a;
}

.fs-styleguide_messege-content {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  -ms-grid-columns: 0.85fr 1fr;
  grid-template-columns: 0.85fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  background-color: #fff;
}

.fs-styleguide_messege-heading {
  color: #3171b9;
  font-size: 2rem;
  line-height: 1.2;
}

.fs-styleguide_messege-heading-span {
  font-size: 2.25rem;
  font-weight: 400;
}

.section-hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('./images/Hero-smaller.jpg');
  background-position: 0px 0px;
  background-size: cover;
}

.hero-grid {
  display: -ms-grid;
  display: grid;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.card {
  max-width: 50rem;
  padding: 2.9rem;
  background-color: #fff;
  border: none!important;
  border-radius: 4px!important;
}

.navbar {
  margin-top: -0.5rem!important;
  background-color: transparent;
}

.white-top-bar {
  background-color: #fff;
}

.section-stap-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 5REM;
  padding-bottom: 5REM;
}

.subtitle {
  margin-bottom: 1rem;
  font-family: ROsanswebtextbold, sans-serif;
  color: black;
}

.heading-black {
  margin-bottom: 1rem;
  color: #000;
  font-size: 2rem;
  line-height: 1.2;
}

.heading {
  margin-bottom: 1rem;
  font-family: ROsanswebtextbold, sans-serif;
  color: #3171b9;
  font-size: 2rem;
  line-height: 1.2;
}

.heading.black {
  color: #000;
}

.button-2 {
  display: inline-block !important;
  width: auto!important;
  height: auto!important;
  background-color: #3171b9;
  font-family: ROsanswebtextbold, sans-serif;
  font-weight: 400;
}

.slider-wrapper {
  display: -ms-grid;
  display: grid;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 2rem;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 4px!important;
  background-color: #f3f3f3;
  height: 16rem;
  align-items: center
}

.text-wrapper {
  width: 100%;
  background-color: transparent;
}

.section-stap-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #f3f3f3;
  overflow: hidden;
  padding-bottom: 10REM;
  padding-top: 5REM;
}

.footer-light {
  position: relative;
  padding: 80px 30px 40px;
  border-bottom: 1px solid #e4ebf3;
  background-color: #f3f3f3;
}

.container {
  width: 100%;
  max-width: 940px;
  margin-right: auto;
  margin-left: auto;
}

.footer-wrapper-two {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.footer-block-two {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.footer-title {
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
}

.footer-link-two {
  margin-top: 20px;
  color: #1a1b1f;
  text-decoration: none;
}

.footer-link-two:hover {
  color: rgba(26, 27, 31, 0.75);
}

.footer-form {
  width: 315px;
  max-width: 100%;
  margin-bottom: 0px;
}

.footer-form-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-form-field {
  height: 48px;
  margin-bottom: 0px;
  padding: 12px 16px;
  border-style: solid;
  border-width: 1px;
  border-color: #a6b1bf;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-size: 14px;
  line-height: 22px;
}

.footer-form-field:hover {
  border-color: #76879d;
}

.footer-form-field:focus {
  border-color: #76879d;
}

.footer-form-field::-webkit-input-placeholder {
  color: rgba(26, 27, 31, 0.8);
  font-size: 14px;
  line-height: 22px;
}

.footer-form-field:-ms-input-placeholder {
  color: rgba(26, 27, 31, 0.8);
  font-size: 14px;
  line-height: 22px;
}

.footer-form-field::-ms-input-placeholder {
  color: rgba(26, 27, 31, 0.8);
  font-size: 14px;
  line-height: 22px;
}

.footer-form-field::placeholder {
  color: rgba(26, 27, 31, 0.8);
  font-size: 14px;
  line-height: 22px;
}

.footer-form-submit {
  width: 50px;
  height: 48px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #1a1b1f;
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.footer-form-submit:hover {
  background-color: #3a4554;
}

.footer-divider-two {
  width: 100%;
  height: 1px;
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: #e4ebf3;
}

.footer-bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-copyright {
  color: #3a4554;
}

.footer-image-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-left: -12px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-social-link {
  margin-left: 12px;
}

.footer-text-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.section-stap-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 5REM;
  padding-bottom: 5REM;
}

.arrow-button {
  font-family: ROsanswebtextbold, sans-serif;
  color: #3171b9;
  font-weight: 400;
}

.paragraph {
  font-size: 1rem;
  line-height: 1.5rem;
}

.heading-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  -ms-grid-columns: 1fr 0.25fr;
  grid-template-columns: 1fr 0.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.image-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.heading-2 {
  position: static;
  color: #242424;
  font-size: 2rem;
  line-height: 1em;
  font-weight: 400;
  letter-spacing: 0px;
  text-transform: none;
}

.question-wrapper {
  display: block;
}

.content-wrapper {
  position: relative;
  z-index: 20;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 70%;
  min-width: 70%;
  margin-right: 2rem;
  margin-left: 2rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dynamic-number {
  display: inline-block;
  padding-right: 5px;
  padding-left: 0em;
  font-size: 2.5rem;
  line-height: 1em;
}

.circle-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 6rem;
  height: 6rem;
  padding-right: 40px;
  padding-left: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #ececec;
}

.output-wrapper {
  position: relative;
  margin-top: 2rem;
}

.html-embed {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #333;
}

.single-image {
  display: none;
  max-width: 120px;
  margin-right: 5px;
  margin-left: 5px;
}

.single-image.start {
  display: block;
}

.div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1000px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .slider-wrapper {
    display: -ms-grid;
    display: grid;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1frr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    border-radius: 4px!important;
    background-color: #f3f3f3;
    height: 26rem;
    align-items: center
  }

  .hide-tablet {
    display: none;
  }

  .fs-styleguide_section {
    grid-column-gap: 2.5rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .spacing-clean {
    margin: 0rem;
    padding: 0rem;
  }

  .show-tablet {
    display: block;
  }

  .max-width-full-tablet {
    width: 100%;
    max-width: none;
  }

  .margin-medium {
    margin: 1.5rem;
  }

  .margin-large {
    margin: 2.5rem;
  }

  .margin-xlarge {
    margin: 3rem;
  }

  .margin-xxlarge {
    margin: 4rem;
  }

  .margin-huge {
    margin: 5rem;
  }

  .margin-xhuge {
    margin: 6rem;
  }

  .margin-xxhuge {
    margin: 8rem;
  }

  .padding-medium {
    padding: 1.5rem;
  }

  .padding-large {
    padding: 2.5rem;
  }

  .padding-xlarge {
    padding: 3rem;
  }

  .padding-xxlarge {
    padding: 4rem;
  }

  .padding-huge {
    padding: 5rem;
  }

  .padding-xhuge {
    padding: 6rem;
  }

  .padding-xxhuge {
    padding: 8rem;
  }

  .margin-top {
    margin-right: 0rem;
    margin-bottom: 0rem;
    margin-left: 0rem;
  }

  .margin-bottom {
    margin-top: 0rem;
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .margin-left {
    margin-top: 0rem;
    margin-right: 0rem;
    margin-bottom: 0rem;
  }

  .margin-right {
    margin-top: 0rem;
    margin-bottom: 0rem;
    margin-left: 0rem;
  }

  .margin-vertical {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .margin-horizontal {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .padding-top {
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }

  .padding-bottom {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .padding-left {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
  }

  .padding-right {
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }

  .padding-vertical {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .padding-horizontal {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .fs-styleguide_messege-heading {
    font-size: 3rem;
    line-height: 0.9;
  }

  .fs-styleguide_messege-heading-span {
    font-size: 1.75rem;
  }

  .heading-black {
    font-size: 3rem;
    line-height: 0.9;
  }

  .heading {
    font-size: 3rem;
    line-height: 0.9;
  }

  .container {
    max-width: 728px;
  }

  .footer-wrapper-two {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .footer-form {
    width: 100%;
    margin-top: 40px;
  }

  .footer-form-container {
    max-width: 350px;
  }

  .heading-wrapper {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .heading-2 {
    font-size: 4.5rem;
    line-height: 5rem;
  }

  .content-wrapper {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .form-2 {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1rem;
  }

  .fs-styleguide_row.is-for-social-icons {
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .fs-styleguide_3-col {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .fs-styleguide_color-sample {
    padding: 2.5rem 2rem;
  }

  .heading-medium {
    font-size: 1.5rem;
  }

  .heading-large {
    font-size: 2rem;
  }

  .text-size-large {
    font-size: 1.25rem;
  }

  .fs-styleguide_section-type {
    font-size: 0.875rem;
  }

  .heading-xlarge {
    font-size: 2.5rem;
  }

  .hide-mobile-landscape {
    display: none;
  }

  .spacing-clean {
    margin: 0rem;
    padding: 0rem;
  }

  .page-padding {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }

  .show-mobile-landscape {
    display: block;
  }

  .max-width-full-mobile-landscape {
    width: 100%;
    max-width: none;
  }

  .margin-medium {
    margin: 1.25rem;
  }

  .margin-large {
    margin: 1.5rem;
  }

  .margin-xlarge {
    margin: 2rem;
  }

  .margin-xxlarge {
    margin: 3rem;
  }

  .margin-huge {
    margin: 3.5rem;
  }

  .margin-xhuge {
    margin: 4rem;
  }

  .margin-xxhuge {
    margin: 4.5rem;
  }

  .padding-medium {
    padding: 1.25rem;
  }

  .padding-large {
    padding: 1.5rem;
  }

  .padding-xlarge {
    padding: 2rem;
  }

  .padding-xxlarge {
    padding: 3rem;
  }

  .padding-huge {
    padding: 3.5rem;
  }

  .padding-xhuge {
    padding: 4rem;
  }

  .padding-xxhuge {
    padding: 4.5rem;
  }

  .margin-top {
    margin-right: 0rem;
    margin-bottom: 0rem;
    margin-left: 0rem;
  }

  .margin-bottom {
    margin-top: 0rem;
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .margin-left {
    margin-top: 0rem;
    margin-right: 0rem;
    margin-bottom: 0rem;
  }

  .margin-right {
    margin-top: 0rem;
    margin-bottom: 0rem;
    margin-left: 0rem;
  }

  .margin-vertical {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .margin-horizontal {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .padding-top {
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }

  .padding-bottom {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .padding-left {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
  }

  .padding-right {
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }

  .padding-vertical {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .padding-horizontal {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .text-style-nowrap {
    white-space: normal;
  }

  .heading-small {
    font-size: 1rem;
  }

  .fs-styleguide_version-details {
    font-size: 0.875rem;
  }

  .fs-styleguide_page-header {
    margin-bottom: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .fs-styleguide_message-outer {
    padding: 0rem;
  }

  .fs-styleguide_message-inner {
    border-radius: 0rem;
  }

  .fs-styleguide_messege-content {
    grid-row-gap: 1.5rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .fs-styleguide_messege-heading {
    font-size: 2.5rem;
    line-height: 0.9;
  }

  .fs-styleguide_messege-heading-span {
    font-size: 1.5rem;
    font-weight: 500;
  }

  .hero-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .heading-black {
    font-size: 2.5rem;
    line-height: 0.9;
  }

  .heading {
    font-size: 2.5rem;
    line-height: 0.9;
  }

  .footer-light {
    padding-right: 15px;
    padding-left: 15px;
  }

  .footer-bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .footer-copyright {
    font-size: 14px;
    line-height: 20px;
  }

  .footer-image-wrapper {
    margin-top: 20px;
  }

  .footer-social-link {
    margin-left: 20px;
  }

  .heading-wrapper {
    max-width: none;
  }

  .heading-2 {
    font-size: 3rem;
  }

  .content-wrapper {
    max-width: none;
    padding-right: 1.7rem;
    padding-left: 1.7rem;
  }
}

@media screen and (max-width: 479px) {
  .show-mobile-portrait {
    display: block;
  }

  .hide-mobile-portrait {
    display: none;
  }

  .spacing-clean {
    margin: 0rem;
    padding: 0rem;
  }

  .fs-styleguide_2-col {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
  }

  .margin-top {
    margin-right: 0rem;
    margin-bottom: 0rem;
    margin-left: 0rem;
  }

  .margin-bottom {
    margin-top: 0rem;
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .margin-left {
    margin-top: 0rem;
    margin-right: 0rem;
    margin-bottom: 0rem;
  }

  .margin-right {
    margin-top: 0rem;
    margin-bottom: 0rem;
    margin-left: 0rem;
  }

  .margin-vertical {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .margin-horizontal {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .padding-top {
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }

  .padding-bottom {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .padding-left {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
  }

  .padding-right {
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }

  .padding-vertical {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .padding-horizontal {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .max-width-full-mobile-portrait {
    width: 100%;
    max-width: none;
  }

  .container {
    max-width: none;
  }

  .footer-wrapper-two {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .footer-block-two {
    margin-top: 40px;
  }

  .footer-bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .footer-image-wrapper {
    margin-top: 20px;
  }

  .heading-wrapper {
    max-width: 100vw;
    -webkit-box-align: start;
    -webkit-align-items: start;
    -ms-flex-align: start;
    align-items: start;
  }

  .image-wrapper {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .heading-2 {
    font-size: 3rem;
    line-height: 3rem;
  }

  .question-wrapper {
    text-align: center;
  }

  .content-wrapper {
    max-width: 100vw;
    padding-bottom: 5rem;
  }

  .form-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .dynamic-number {
    font-size: 2rem;
    line-height: 2rem;
  }

  .circle-wrapper {
    width: 4rem;
    height: 4rem;
  }

  .single-image {
    display: none;
  }
}

#w-node-ba2c3ef8-4b44-8962-f4cd-876999fb8941-7543417c {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

@font-face {
  font-family: 'ROsanswebtextbold';
  src: url('./fonts/ROsanswebtextbold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'ROsanswebtextregular';
  src: url('./fonts/ROsanswebtextregular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
.bg-yellow {
  background-color: #fe0;
}
.text-legend {
  color: black;
  text-align: center;
  font-weight: bold;
}
.slider-value-wrapper {
  width: 70%;
}

.w-button {
  align-self: flex-start;
  padding: 9px 15px;
  background-color: #3171b9;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
}

.w-button-presterend {
  padding: 9px 15px;
  background-color: #E41F12;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
  margin-right: 2rem;
}
.w-button-netwerkend {
  padding: 9px 15px;
  background-color: #FFEE00;
  color: black;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
  margin-right: 2rem;
}
.w-button-rechtmatig {
  padding: 9px 15px;
  background-color: #069FE3;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
  margin-right: 2rem;
}
.w-button-responsief {
  padding: 9px 15px;
  background-color: #7AB92A;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
}

.w-button-presterend:hover {
  padding: 9px 15px;
  background-color: #E41F12;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
  margin-right: 2rem;
  color: white;
  cursor: pointer;
}
.w-button-netwerkend:hover {
  padding: 9px 15px;
  background-color: #FFEE00;
  color: black;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
  margin-right: 2rem;
  cursor: pointer;
}
.w-button-rechtmatig:hover {
  padding: 9px 15px;
  background-color: #069FE3;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
  margin-right: 2rem;
  color: white;
  cursor: pointer;
}
.w-button-responsief:hover {
  padding: 9px 15px;
  background-color: #7AB92A;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
  color: white;
  cursor: pointer;
}

.w-button:hover {
  display: inline-block!important;
  padding: 9px 15px;
  background-color: #3171b9;
  color: white;
  cursor: pointer;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  border-radius: 0;
}

#stap-1 > div > div > div > div > a {
  display: inline-block!important;
}

.card.margin-top {
  margin-top: 4rem;
}

.card.margin-top.transparant {
  background-color: transparent;
}
.image-absolute {
  position: absolute;
  left: 0px;
  bottom: -7.1rem;
  overflow: hidden;
}
.toelichting {
  display: flex;
  gap: 10px;
  gap: 10px 20px; /* row-gap column gap */
  row-gap: 10px;
  column-gap: 20px;
  justify-content: space-between;
  margin-top: 2REM;
}

@media screen and (max-width: 1280px) {
  h1 {
    padding-left: 2.5rem!important;
  }
}

.button-grid {
  margin-bottom: 2rem;
}